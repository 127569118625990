import ApplicationController from "controllers/application_controller"
import { destroy, get, post, put } from "@rails/request.js"

export default class extends ApplicationController {
  static outlets = ["layout--top-nav--search--component"]
  static values  = { name: String, selectable: Boolean, slideOut: Boolean, url: String }
  static targets = ["actions", "select", "selectedCount", "selectVisible"]

  async connect() {
    this.element.table = this

    this.layoutTopNavSearchComponentOutlet.perTarget.value = this._calculatePer();

    if (!this.element.getAttribute("initialized")) {
      if (this.selectableValue) {
        await post("/tables", { body: { id: this.nameValue } })
      }
    }

    this.element.setAttribute("initialized", true)

    document.addEventListener("turbo:frame-render", () => {
      if (this._selected().length) {
        this.actionsTarget.classList.remove("hidden")
      }
    })

    setTimeout(() => {
      this.layoutTopNavSearchComponentOutlet.submitForm();
    }, 250);
  }

  async deselectAll(event) {
    if (event) { event.preventDefault() }
    this.selectTargets.forEach(item => item.checked = false)
    this.selectVisibleTarget.checked = false
    this._removeSelected(["all"])
  }

  reset() {
    post("/tables", { body: { id: this.nameValue } })
    this.actionsTarget.classList.add("hidden")
    this.deselectAll()
  }

  async selectAll(event) {
    event.preventDefault()
    let response = await get(`${this.urlValue}?format=json&per=99999999`)
    let data     = await response.text
    let records  = JSON.parse(data)
    this._addSelected(records.map(record => record.id))
    this.selectTargets.forEach(item => item.checked = true)
    this.selectVisibleTarget.checked = true
  }

  async toggleVisible() {
    let ids = this.selectTargets.map(item =>
      item.getAttribute("data-layout--table-id-param")
    )
    if (this._selected().length == this.selectTargets.length) {
      this.selectTargets.forEach(item => item.checked = false)
      this._removeSelected(ids)
      this.actionsTarget.classList.add("hidden")
    } else {
      this.selectTargets.forEach(item => item.checked = true)
      this._addSelected(ids)
    }
  }

  toggleSelect(event) {
    if (event.currentTarget.checked) {
      this._addSelected([event.params.id])
    } else {
      this._removeSelected([event.params.id])
    }
  }

  updatePage(event) {
    this.layoutTopNavSearchComponentOutlet.page(event.params.page)
  }

  visit(event) {
    const url = event.params["url"]

    if (!url || url === '') {
      return false;
    }

    if (this._noVisit(event.target)) {
      return false;
    }

    if (this.slideOutValue) {
      document.querySelector("#slide-out").slideOut.open()
      Turbo.visit(url, { frame: "slide-out-frame" })
    } else {
      Turbo.visit(url)
    }
  }

  async _addSelected(selected) {
    let body     = { table: { selected: selected } }
    let response = await put(`/tables/${this.nameValue}`, { body: body })
    let data     = await response.text
    let table    = JSON.parse(data)
    this.selectedCountTarget.innerHTML = table.selected.length
    this.actionsTarget.classList.remove("hidden")
  }

  _calculatePer() {
    return parseInt((window.innerHeight - 200) / 53);
  }

  _noVisit(target) {
    if (
      target.nodeName === "A"      ||
      target.nodeName === "INPUT"  ||
      target.nodeName === "BUTTON" ||
      target.nodeName === "LABEL" ||
      target.classList.contains("button")
    ) {
      return true
    }

    return false;
  }

  async _removeSelected(selected) {
    let body     = { table: { selected: selected } }
    let response = await destroy(`/tables/${this.nameValue}`, { body: body })
    let data     = await response.text
    let table    = JSON.parse(data)
    this.selectedCountTarget.innerHTML = table.selected.length
    if (this._selected().length == 0) {
      this.actionsTarget.classList.add("hidden")
    }
  }

  _selected() {
    return this.selectTargets.filter((target) => { return (target.checked) })
  }
}
