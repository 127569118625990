import ApplicationController from "controllers/application_controller"
import { get } from "@rails/request.js"

export default class extends ApplicationController {
  static targets = [ "form", "last", "loading", "page" ]
  static values  = { url: String }

  connect() { this.spinner = document.getElementById("badges-spinner") }

  load() {
    if (this.hasLastTarget) {
      let last    = this.lastTarget.value
      let loading = this.loadingTarget.value == "true"
      if ((window.innerHeight + window.scrollY + 250) >= document.body.offsetHeight && !loading) {
        if (last == "false") {
          this.spinner.classList.remove("hidden")
          this.loadingTarget.value = true
          let params = new URLSearchParams()
          let page   = parseInt(this.pageTarget.value) + 1
          let active = document.getElementsByClassName("badge-filter-option active")[0]
          let search = active.getAttribute("data-type") || ""
          params.append("page", page)
          params.append("search", search)
          const request = get(`${this.urlValue}?${params}`, { responseKind: "turbo-stream" })
          request.then((response) => {
            this.spinner.classList.add("hidden")
          })
        }
      }
    }
  }
}
