import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = [ 'container', 'overlay', 'dialogue' ]
  @opened  = false

  connect: ->
    @element['modal'] = @

  fade: ->
    $(@overlayTarget).toggleClass('opacity-0 opacity-100')

  slide: ->
    $(@dialogueTarget).toggleClass('opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 opacity-100 translate-y-0 sm:scale-100')

  close: (event) ->
    @opened = false
    event.preventDefault() if event?

    @fade()
    @slide()

    # Timeout is needed so toggle and reload happens after transitions
    setTimeout =>
      @toggle()
      $('#side-nav').css('z-index': '20') unless $('.slide-out-overlay').is(':visible')
    , 300

  open: ->
    $('#side-nav').css('z-index': '0')
    @opened = true
    @toggle()

    # Timeout is needed so transitions take effect after toggle executes
    setTimeout =>
      @fade()
      @slide()
    , 10

  toggle: ->
    $(@containerTarget).toggleClass('hidden')
