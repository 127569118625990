import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @values = {
    token:       String,
    successUrl:  String,
    redirectUrl: String,
    turboFrame:  String,
  }

  launch: ->
    plaid = Plaid.create
      token: @tokenValue
      onSuccess: =>
        $.ajax
          url: @successUrlValue
          method: 'post'
          success: (data) =>
            frame = @turboFrameValue
            Turbo.visit(@redirectUrlValue, frame: frame)

    plaid.open()
