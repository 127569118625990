import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static values  = { types: Array }
  static targets = ["amount", "description", "name"]

  connect() {
    $(document).on("change", "#reward_reward_type_id", (event) => {
      this.prefill(event);
    });
  }

  disconnect() {
    $(document).off("change", "#reward_reward_type_id")
  }

  prefill(event) {
    let value = event.target.value
    let type  = this.typesValue.find(obj => {
      return parseInt(obj.id) === parseInt(value)
    })
    let text  = this.typesValue.filter(obj => {
      let div = document.createElement("div");
      div.innerHTML = this.descriptionTarget.editor.getDocument().toString()
      return div.innerText.replace(/(\r\n|\n|\r)/gm, "") == obj.description
    })

    this.amountTarget.value = type.amount
    this.nameTarget.value   = type.name

    if (this.descriptionTarget.editor.getDocument().isEmpty() || text.length >= 1) {
      this.descriptionTarget.editor.loadHTML(type.description)
    }
  }
}
