import ApplicationController from "controllers/application_controller"
import Handsontable from "handsontable"
import { post } from "@rails/request.js"

export default class extends ApplicationController {
  static values  = { budgets: Array, users: Array, rewardTypes: Array, url: String }
  static targets = ["confirm", "rewardsAmount", "rewardsCount", "sheet"]

  afterChange(changes, source) {
    if (source != "loadData" && source != "customChange") {
      for (const change of changes) {
        if (["reward_type", "budget", "user"].indexOf(change[1]) >= 0) {
          let row  = change[0]
          let attr = change[1]
          let val  = change[3]
          let arr  = `${attr.camelCase()}sValue`
          let obj  = this[arr].find((x) => x.name == val)
          let prop = `${attr}_id`
          if (obj) {
            this.sheet.setSourceDataAtCell(row, prop, obj.id, "customChange")
            if (change[1] == "reward_type") { this.populateTypes(row, obj) }
          }
        }
      }
      if (this.element.querySelector(".spin")) { this.element.querySelector(".spin").remove() }
      this.updateModal()
    }
  }

  beforeChange(changes, source) {
    if (source != "loadData" && source != "customChange") {
      if (changes.filter((change) => change[1] == "reward_type").length > 0) {
        this.spinner()
      }
    }
  }

  columns() {
    let cols = [
      { name: "Reward type", type: "autocomplete", data: "reward_type", source: this.rewardTypesValue.map((t) => t.name), strict: true, width: undefined },
      { name: "Budget",      type: "autocomplete", data: "budget", source: this.budgetsValue.map((b) => b.name), strict: true, width: undefined },
      { name: "Employee",    type: "autocomplete", data: "user",   source: this.usersValue.map((e) => e.name), strict: true, width: undefined },
      { name: "Reward name", type: "text",         data: "name" , width: undefined },
      { name: "Amount",      type: "numeric",      data: "amount", width: 30 },
      { name: "Date",        type: "date",         data: "date", dateFormat: "MM/DD/YYYY", width: 40 },
      { name: "Note",        type: "text",         data: "description", width: 150 },
    ]
    if (this.rewardTypesValue.length == 0) {
      cols.shift()
      if (this.budgetsValue.length == 0) {
        cols.shift()
      }
    } else if (this.budgetsValue.length == 0) {
      cols.splice(1, 1)
    }
    return cols
  }

  connect() {
    this.sheet = new Handsontable(this.sheetTarget, {
      afterChange: this.afterChange.bind(this),
      beforeChange: this.beforeChange.bind(this),
      rowHeaders: false,
      colHeaders: this.columns().map((c) => c.name),
      columns: this.columns(),
      height: "auto",
      licenseKey: "non-commercial-and-evaluation",
      stretchH: "all",
      data: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      minSpareRows: 1,
      colWidths: this.columns().map((c) => c.width),
      maxRows: 100,
    })

    this.confirmTarget.addEventListener("click", (event) => {
      event.preventDefault()
      this.submit()
    })
  }

  disconnect() {
    this.sheet.destroy()
  }

  populateTypes(row, obj) {
    this.sheet.setDataAtRowProp(row, "name", obj.name, "customChange")
    this.sheet.setDataAtRowProp(row, "amount", obj.amount, "customChange")
    this.sheet.setDataAtRowProp(row, "description", obj.description, "customChange")
  }

  spinner() {
    if (!this.element.querySelector(".spin")) {
      let div = document.createElement("div")
      div.className = "flex absolute inset-0 justify-center items-center spin bg-gray-700 opacity-50 z-30"
      div.innerHTML = "<div class='spinner'><div></div><div></div><div></div><div></div></div>"
      this.element.append(div)
    }
  }

  async submit() {
    let rewards  = this.sheet.getSourceData()
    for (const reward of rewards.filter((r) => Object.keys(r).length > 0)) {
      delete reward.user
      delete reward.budget
      delete reward.reward_type
      let date = reward.date || moment().format("MM/DD/YYYY")
      reward.date = moment(date, "MM/DD/YYYY").format("YYYY-MM-DD")
    }
    let response = await post(`${this.urlValue}`, { body: { rewards: rewards }, contentType: "application/json", responseKind: "json" })
    if (response.ok) {
      this.sheet.clear()
      this.sheet.deselectCell()
      Notice.show({ type: "success", message: "Reward payments initiated" })
    } else {
      let errors = JSON.parse(await response.text).errors
      for (const [i, row] of errors.entries()) {
        for (const attr in row) {
          let error = row[attr]
          let col   = this.sheet.propToCol(attr)
          let cell  = this.sheet.getCell(i, col)
          if (cell) {
            cell.setAttribute("title", error)
            this.sheet.setCellMeta(i, col, "valid", false)
          }
        }
      }
      this.sheet.render()
      Notice.show({ type: "error", message: "Invalid submission" })
    }
    document.querySelector(".modal-control").modal.close()
  }

  updateModal() {
    let count   = this.sheet.getSourceData().filter((r) => Object.keys(r).length > 0).length
    let amounts = this.sheet.getSourceData().map((r) => parseFloat(r.amount))
    let total   = amounts.filter((n) => n).reduce((x, y) => x + y, 0)
    this.rewardsCountTarget.innerHTML  = count
    this.rewardsAmountTarget.innerHTML = total
  }
}
