import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  add(event) {
    Array.from(document.querySelectorAll(".reward-type")).forEach(
      (el) => el.classList.remove("active")
    );

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    let div       = document.createElement("div")
    div.innerHTML = content
    let elem      = div.firstElementChild
    let parent    = this.targetTarget.parentElement
    parent.insertBefore(elem, this.targetTarget)
    elem.classList.add("active")
  }

  summarize(event) {
    let name   = event.target.closest(".reward-type").querySelector(".name-input").value
    let amount = event.target.closest(".reward-type").querySelector(".amount-input").value
    let text   = `${name} $${amount}`
    let elem   = event.target.closest(".reward-type").querySelector(".summary .name")
    elem.innerHTML = text
  }

  toggle(event) {
    event.target.closest(".reward-type").classList.toggle("active")
  }
}
