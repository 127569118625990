import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "input" ]

  connect(){
    this.scrollHeight = this.inputTarget.scrollHeight || this._minHeight();
    if (this.scrollHeight > this._minHeight()) {
      this._setHeight(this.scrollHeight)
    } else {
      this._setHeight(this._minHeight())
    }
  }

  resize(){
    if (this.inputTarget.scrollHeight > this._minHeight()) {
      this._setHeight(this.inputTarget.scrollHeight)
    }
  }

  _minHeight() {
    return (parseInt(this.inputTarget.getAttribute("rows")) || 2) * 18 + 14
  }

  _setHeight(height){
    this.inputTarget.setAttribute("style", `height: ${height}px; overflow-y: hidden;`)
  }
}
