import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["field", "list"]

  connect() {
    this.fieldTarget.addEventListener("change", (event) => {
      Object.keys(event.currentTarget.files).forEach((n) => {
        let file   = event.currentTarget.files[n]
        let item = document.createElement("div")
        item.innerHTML = file.name
        this.listTarget.appendChild(item)
      })
    })
  }

  trigger(event) {
    event.preventDefault()
    this.fieldTarget.click()
  }
}
