import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @values = { target: String }

  connect: ->
    target = @targetValue
    $(document).on 'turbo:before-stream-render', (e) ->
      # Open modal if turbo stream is for progress tracker.
      if e.target.innerHTML.indexOf('progress-tracker') >= 0
        modal = $("##{target}")[0].modal
        modal.open() unless modal.opened

  disconnect: ->
    $(document).off 'turbo:before-stream-render'
