import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["input"]

  connect() {
    var comments = document.getElementById("badge-comments-frame");
    var observer = new MutationObserver(this._observe)
    this.observer = observer
    observer.observe(comments, { childList: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  add() {
    // Add temp element to expand the scroll area, because the new comment isn't
    // rendered until after the turbo:submit-end event, so this temp element
    // expands the scroll area and is removed by the turbo response.
    var comments = document.getElementById("badge-comments-frame");
    var temp     = document.createElement("div");
    temp.id      = "temp-comment";
    temp.classList.add("h-96");
    comments.appendChild(temp);
    // Clear the input of the previous message.
    this.inputTarget.value = "";
  }

  send() {
    this.inputTarget.closest("form").requestSubmit()
  }

  _observe() {
    var slideOut = document.getElementById("slide-out-frame");
    slideOut.scrollTo(
      {
        top:      slideOut.scrollHeight,
        behavior: "smooth",
      }
    );
  }
}
