import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "input" ]

  initialize() {
    this.control = this.element.closest(".radiobox-control")
  }

  connect() {
    this.inputTargets.forEach((element) => {
      element.checked && this.activate(element.closest(".radiobox"))
    })
  }

  selectRadio(event) {
    this.inputTargets.forEach((element) => {
      this.deactivate(element.closest(".radiobox"))
    })
    this.activate(event.target.closest(".radiobox"))
  }

  activate(element) {
    element.classList.add("active")
  }

  deactivate(element) {
    element.classList.remove("active")
  }
}
