import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static values = { count: String }

  connect() {
    const countElement = document.createElement("div");
    
    countElement.classList.add(
      "text-xs",
      "rounded-full",
      "absolute",
      "top-3",
      "right-4",
      "bg-orange",
      "text-white",
      "w-4",
      "h-4",
      "flex",
      "items-center",
      "justify-center"
    );
    countElement.innerHTML = this.countValue;

    this.element.appendChild(countElement);
  }
}
