import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static values = {
    target: String
  }

  // TODO: this should probably use a stimulus Target instead of a Value named target
  open(event) {
      event.preventDefault();
      document.querySelector(`#${this.targetValue}`).modal.open()
  }
}
