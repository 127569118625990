import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

import ScrollTo from "stimulus-scroll-to"
import Chart from "stimulus-chartjs"
import NestedForm from "stimulus-rails-nested-form"

const application = Application.start();

application.register("scroll-to", ScrollTo)
application.register("chart", Chart)
application.register("nested-form", NestedForm)

const context = require.context("./controllers", true, /\.(js|coffee)$/);
const contextComponents = require.context("../components", true, /_controller\.(js|coffee)$/)

application.load(
    definitionsFromContext(context).concat(
      definitionsFromContext(contextComponents)
  )
)
application.debug = process.env.NODE_ENV === "development";
