import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["control"]
  static values  = {
    cardId:    String,
    container: String,
    env:       String,
    signature: String
  }

  connect() {
    CheckbookVccDetails({
      env:       this.envValue,
      card_id:   this.cardIdValue,
      signature: this.signatureValue,
    }).render(this.containerValue)

    let height = this.controlTarget.offsetWidth * 0.6296296296296297
    this.controlTarget.style["height"] = `${height}px`
  }
}
