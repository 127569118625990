import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = [ 'content', 'cta' ]

  connect: ->
    $(@ctaTarget).on 'click', (e) =>
      e.preventDefault()
      if $(@contentTarget).hasClass('hidden')
        @open()
      else
        @close()

  disconnect: ->
    $(@ctaTarget).off('click')

  close: ->
    $(@ctaTarget).find('span').removeClass('origin-center transform rotate-135')
    $(@contentTarget).removeClass('z-10 py-16 max-h-1/2')
    $(@ctaTarget).removeClass('z-10')
    setTimeout =>
      $(@contentTarget).addClass('max-h-0')
      $(@contentTarget).find('.card').addClass('opacity-0')
      $(@contentTarget).addClass('hidden')
    , 200

  open: ->
    $(@ctaTarget).find('span').addClass('origin-center transform rotate-135')
    $(@contentTarget).removeClass('hidden')
    $(@ctaTarget).addClass('z-10')
    setTimeout =>
      $(@contentTarget).removeClass('max-h-0')
      $(@contentTarget).addClass('z-10 py-16 max-h-0 max-h-1/2')
      $(@contentTarget).find('.card').removeClass('opacity-0')
    , 100
