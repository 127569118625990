import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  toggle(event) {
    let item      = event.currentTarget
    let body      = item.getElementsByClassName("body")[0]
    let indicator = item.getElementsByClassName("indicator")[0]
    if (body.classList.contains("h-0")) {
      body.classList.remove("h-0")
      indicator.classList.add("rotate-180")
    } else {
      body.classList.add("h-0")
      indicator.classList.remove("rotate-180")
    }
  }
}
