import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static values = { offset: String, placement: String, target: String }

  connect(){
    var tooltip, event, hide, hideEvents, i, j, len, len1, offset, options, results, show, showEvents, target, tooltipInstance;
    target  = document.getElementById(this.targetValue);
    tooltip = this.element
    options = {
      placement: this.placementValue,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, this.offsetValue]
          }
        },
        {
          name: "computeStyles",
          options: {
            adaptive: false
          }
        }
      ]
    };
    tooltipInstance = window.tooltip(target, tooltip, options);
    show = function() {
      tooltip.setAttribute("data-show", "");
      return tooltipInstance.update();
    };
    hide = function() {
      return tooltip.removeAttribute("data-show");
    };
    showEvents = ["mouseenter", "focus"];
    hideEvents = ["mouseleave", "blur", "click"];
    for (i = 0, len = showEvents.length; i < len; i++) {
      event = showEvents[i];
      target.addEventListener(event, show);
    }
    results = [];
    for (j = 0, len1 = hideEvents.length; j < len1; j++) {
      event = hideEvents[j];
      results.push(target.addEventListener(event, hide));
    }
  }
}
