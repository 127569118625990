import ApplicationController from "controllers/application_controller"
import { useClickOutside } from "stimulus-use"

export default class extends ApplicationController {

  connect(){
    useClickOutside(this);
  }

  close(event) {
    event.target.classList.remove("open")
  }

  open(event) {
    event.target.classList.add("open")
  }
}
