import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = ['content', 'left', 'right']

  connect: ->
    @toggleButtons()
    $(@contentTarget).scrollLeft(0)
    $(@rightTarget).on 'click', (e) =>
      e.preventDefault()
      leftPos = $(@contentTarget).scrollLeft()
      width   = $(@contentTarget).width()
      $(@contentTarget).animate(
        {scrollLeft: leftPos + width}, 500, => @toggleButtons()
      )

    $(@leftTarget).on 'click', (e) =>
      e.preventDefault()
      leftPos = $(@contentTarget).scrollLeft()
      width   = $(@contentTarget).width()
      $(@contentTarget).animate(
        {scrollLeft: leftPos - width}, 500, => @toggleButtons()
      )

    $(@contentTarget).on 'scroll', =>
      @toggleButtons()

  disconnect: ->
    $(@leftTarget).off('click') if @hasLeftTarget
    $(@rightTarget).off('click') if @hasRightTarget
    $(@contentTarget).off('scroll') if @hasContentTarget

  toggleButtons: ->
    if $(@contentTarget).scrollLeft() == 0
      $(@leftTarget).hide()
    else
      $(@leftTarget).show()

    right = $(@contentTarget).scrollLeft() + $(@contentTarget).width()
    if right == $(@contentTarget)[0].scrollWidth
      $(@rightTarget).hide()
    else
      $(@rightTarget).show()
