import ApplicationController from "controllers/application_controller"
import { useClickOutside } from "stimulus-use"

export default class extends ApplicationController {
  static targets = [ "control" ]

  connect(){
    useClickOutside(this);
  }

  toggleMenu(event){
    event.preventDefault();
    this.controlTarget.classList.toggle("active")
  }

  clickOutside(event) {
    if(!this.controlTarget.classList.contains("active")) return
    this.closeMenu()
  }

  closeMenu(){
    this.controlTarget.classList.remove("active")
  }
}
