// UX interactions for goals form
import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["container", "input", "trigger"];

  connect() {
    this.toggle();
  }

  toggle() {
    if (!this.hasContainerTarget) return

    (this.triggerTarget.value == "true") ? this.show() : this.hide()
  }

  show(){
    this.containerTarget.classList.remove("hidden");
    for (var i = 0; i < this.inputTargets.length; i++) {
      this.inputTargets[i].disabled = false;
      this.inputTargets[i].checked  = true
      this.inputTargets[i].value    = "1"
    }

  }

  hide(){
    this.containerTarget.classList.add("hidden");
    for (var i = 0; i < this.inputTargets.length; i++) {
      this.inputTargets[i].disabled = true;
      this.inputTargets[i].checked  = false
      this.inputTargets[i].value    = "0"
    }
  }
}
