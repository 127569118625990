import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = ['form', 'input']

  connect: ->
    $(@inputTarget).on 'keyup', (e) =>
      if e.keyCode == 27
        $(@inputTarget).val('')
        @formTarget.requestSubmit()
        $(@inputTarget).trigger('keyup')
      else
        clearTimeout(window.searchTypeTimeout) if window.searchTypeTimeout
        window.searchTypeTimeout = setTimeout (=>
          @formTarget.requestSubmit()
        ), 300

  disconnect: ->
    $(@inputTarget).off('keyup')
