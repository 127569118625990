import ApplicationController from "controllers/application_controller"
import Persona from "persona"
import { put } from "@rails/request.js"

export default class extends ApplicationController {
  static values  = {
    accountUrl:   String,
    companyId:    String,
    envId:        String,
    inquiryId:    String,
    sessionToken: String,
    templateId:   String,
  }

  connect = () => {
    this.spinner = document.getElementById("spinner-control")
  }

  async launch(event) {
    if (this.spinner) { this.spinner.classList.remove("hidden") }
    event.preventDefault()

    const client = new Persona.Client({
      sessionToken: this.sessionTokenValue,
      inquiryId: this._inquiryId(),
      templateId: this._templateId(),
      environmentId: this.envIdValue,
      referenceId: this.companyIdValue,
      onEvent: (name, metadata) => { this.onEvent(name, metadata) },
      onReady: () => {
        client.open()
        if (this.spinner) { this.spinner.classList.add("hidden") }
      },
      onComplete: ({ inquiryId, status, fields }) => {
        this.onComplete(inquiryId, status, fields)
      }
    })
  }

  async onComplete(kyc_id, status, fields) {
    if (this.spinner) { this.spinner.classList.remove("hidden") }

    let body = { account: { kyc_id: kyc_id, kyc_status: "completed" } }

    await put(this.accountUrlValue, { body: JSON.stringify(body) })

    document.getElementById("wizard-form").requestSubmit()
  }

  onEvent(name, metadata) {
    if (name === "start") {
      let body = { account: { kyc_id: metadata["inquiryId"], kyc_status: "created" } }
      put(this.accountUrlValue, { body: JSON.stringify(body) })
    }
  }

  _inquiryId() {
    if (this.sessionTokenValue.toString().length > 0) { return this.inquiryIdValue }
  }

  _templateId() {
    if (this.sessionTokenValue.toString().length == 0) { return this.templateIdValue }
  }
}
