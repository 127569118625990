import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["amountType", "amountTypeSelect", "fixedAmount"]

  connect() {
    this.toggleAmountType()
    return $(this.amountTypeSelectTarget).on("change", () => {
      return this.toggleAmountType();
    });
  }

  toggleAmountType() {
    var type = $(this.amountTypeSelectTarget).val().camelCase();
    $(this.amountTypeTargets).hide();

    return $(this[`${type}AmountTargets`]).show();
  }
}
