import ApplicationController from "controllers/application_controller"
import { post } from "@rails/request.js"

export default class extends ApplicationController {

  static values  = {
    autoLaunch:  Boolean,
    bankType:    String,
    oauthUrl:    String,
    token:       String,
    redirectUrl: String,
    successUrl:  String,
    turboFrame:  String,
  }

  connect() {
    if (this.autoLaunchValue) { this.launch() }
  }

  async launch(event) {
    if (event) { event.preventDefault() }

    let plaid = Plaid.create({
      token:               this.tokenValue,
      onSuccess:           this._onSuccess.bind(this),
      receivedRedirectUri: this.oauthUrlValue,
    })
    plaid.open()
  }

  async _onSuccess(token, meta) {
    let spinner = document.getElementById("spinner-control")
    if (spinner) { spinner.classList.remove("hidden") }

    let body = {
      bank:         { account_type: this.bankTypeValue },
      public_token: token,
      account:      meta.account,
    }

    const response = await post(
      this.successUrlValue,
      { body: JSON.stringify(body) },
    )
    const data     = await response.text
    let   json     = JSON.parse(data)
    let   url      = this.redirectUrlValue || json.redirect_url

    Turbo.visit(url, { frame: this.turboFrameValue })
  }
}
