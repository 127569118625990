import ApplicationController from "controllers/application_controller"
import Handsontable from "handsontable"
import { post } from "@rails/request.js"

export default class extends ApplicationController {
  static values = {
    departments: Object,
    employees:   Array,
    page:        String,
    per:         String,
    url:         String
  }

  connect() {
    let columns = [
      { type: "text",         data: "name", readOnly: true },
      { type: "autocomplete", data: "department_name", source: Object.keys(this.departmentsValue), strict: true }
    ]

    this.sheet = new Handsontable(this.element, {
      afterChange: this.update.bind(this),
      rowHeaders: false,
      colHeaders: true,
      colHeaders: ["Name", "Department"],
      columns: columns,
      data:    this.employeesValue,
      height: "auto",
      licenseKey: "non-commercial-and-evaluation",
      stretchH: "all",
    });
  }

  update(changes, source) {
    if (source != "loadData") {
      let data = this.sheet.getSourceData()
      let roles = []
      for (const change of changes) {
        roles.push(
          {
            department_id: this.departmentsValue[change[3]],
            id:            data[change[0]].role_id
          }
        )
      }

      let params = new URLSearchParams()
      params.append("page", this.pageValue)
      params.append("per", this.perValue)

      post(`${this.urlValue}?${params}`, { body: { roles: roles } })
    }
  }
}
