import consumer from '../../channels/consumer'
import ApplicationController from "controllers/application_controller"

window.Notice = {}
Notice.show = (args) ->
  $('[data-controller="ui--notice"]')[0]['notice'].show(args)

export default class extends ApplicationController

  @targets = ['notice']
  @values  = { message: String, type: String, user: String }

  connect: ->
    @element['notice'] = @
    @show(type: @typeValue, message: @messageValue) if @messageValue.length > 0
    if @userValue?
      @channel = consumer.subscriptions.create(
        { channel: 'NoticeChannel', user_id: @userValue }

        received: (data) =>
          @show(data)
      )

  close: (e) ->
    e.preventDefault()
    @hide()
    setTimeout =>
      @remove()
    , 500

  hide: ->
    if @hasNoticeTarget
      $(@noticeTarget).removeClass('bottom-5').addClass('-bottom-20')

  icon: (type) ->
    {
      'error':   'warning',
      'success': 'thumb_up',
    }[type] || 'info'

  remove: ->
    if @hasNoticeTarget
      $(@noticeTarget).remove()

  show: (args) ->
    $(@element).html(@template(args))
    setTimeout =>
      $(@noticeTarget).removeClass('-bottom-20')
      $(@noticeTarget).addClass('bottom-4')
    , 10

    setTimeout =>
      @hide()
    , 7500
    setTimeout =>
      @remove()
    , 8000

  template: (args) ->
    "<div class='flex notice -bottom-20 #{@type(args.type)}' data-ui--notice-target='notice'>
      <div class='flex-none pr-3'>
        <i class='material-icons-round text-lg'>#{@icon(@type(args.type))}</i>
      </div>
      <div class='flex-grow py-1 message'>#{args.message}</div>
      <div class='flex-none'>
        <a href='#' class='close' data-action='click->ui--notice#close'>
          <i class='material-icons-round text-lg'>cancel</i>
        </a>
      </div>
    </div>"

  type: (value) ->
    {
      alert:  'error',
      notice: 'success',
    }[value] || value
