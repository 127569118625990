import ApplicationController from "controllers/application_controller"
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  connect() {
    this.autosaveTimeout = null
  }

  async autosave(event) {
    if (this.autosaveTimeout) { clearTimeout(this.autosaveTimeout) }
    this.autosaveTimeout = setTimeout(() => {
      if (event.params.autosave == "json") {
        let meth = this.element.querySelectorAll('[name="_method"]')[0]
        let url  = this.element.getAttribute("action")
        let type = (meth) ? meth.getAttribute("value") : this.element.getAttribute("method")

        let formData = new FormData(this.element)
        let data     = {}

        formData.forEach((value, key) => {
          this.setProperty(data, key, value);
        })

        let request = new FetchRequest(
          type,
          url,
          { body: data, contentType: "application/json", responseKind: "json" },
        )
        request.perform()
      } else {
        this.element.requestSubmit()
      }
    }, 250)
  }

  setProperty(obj, path, value) {
    const keys = path.split('[').map(key => key.replace(']', ''));
    let current = obj;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      }
    });
  }
}
