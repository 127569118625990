import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['twiceMonthly', 'everyOtherWeek', 'frequency', 'frequencyInput', 'monthly', 'weekly'];

  connect() {
    this.toggleFrequency();
    this.frequencyTarget.addEventListener('change', () => {
      this.toggleFrequency();
    });
  }

  toggleFrequency() {
    let frequency = this.frequencyTarget.value.camelCase();
    this.frequencyInputTargets.forEach(target => {
      target.classList.add('hidden')
      target.classList.remove('inline')
    })
    this[`${frequency}Targets`].forEach(target => {
      target.classList.remove('hidden')

      if (target.dataset.containerType == 'inline'){
        target.classList.add('inline')
      }
    })
  }
}
