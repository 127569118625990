import ApplicationController from "controllers/application_controller"
import { get } from "@rails/request.js"

export default class extends ApplicationController

  @targets = [ "container", "overlay", "slider" ]

  connect: ->
    @element["slideOut"] = @
    if Browser.param("slide_out")
      @open()
      Turbo.visit(Browser.param("slide_out"), { frame: "slide-out-frame" })

  fade: ->
    $(@overlayTarget).toggleClass("opacity-0 opacity-100")

  slide: ->
    $(@sliderTarget).toggleClass("lg:translate-x-full lg:translate-x-0 translate-y-full translate-y-0")

  close: (event) ->
    event? && event.preventDefault()
    return unless $(@element).hasClass("slide-out-open")

    $(@element).removeClass("slide-out-open")

    @fade()
    @slide()

    # Timeout is needed so toggle and reload happens after transitions
    setTimeout =>
      @toggle()
      $("turbo-frame.slide-out-content")[0].reload() if $(@element).attr("loading")
      $("#side-nav").css("z-index": "20")
    , 300

  open: (event) ->
    event? && event.preventDefault()
    return if $(@element).data("open")

    $(@element).addClass("slide-out-open")

    $("#side-nav").css("z-index": "0")
    $("#slide-out-frame").html(@spinner())
    @toggle()

    # Timeout is needed so transitions take effect after toggle executes
    setTimeout =>
      @fade()
      @slide()
    , 10

  spinner: ->
    "<div class='flex h-full justify-center items-center'>
      <div class='spinner'><div></div><div></div><div></div><div></div></div>
    </div>"

  toggle: ->
    $("body").toggleClass("overflow-hidden")
    $(@containerTarget).toggleClass("hidden")

# Reset content scroll in case the element was scrolled down previously.
$(document).on "turbo:before-stream-render", (e) ->
  if e.target.innerHTML.indexOf("id='slide-out-frame'") >= 0
    $(".slide-out-content").scrollTop(0)
