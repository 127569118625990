import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = ['back', 'form', 'next', 'step']

  connect: ->
    if $(@element).data('init') == true
      $(@element).removeClass('opacity-0')
    else
      $(@element).addClass('tranistion-opacity duration-1000')
      setTimeout =>
        $(@element).removeClass('opacity-0')
      , 100

    for next in @nextTargets
      $(next).on 'click', (e) =>
        e.preventDefault()
        @formTarget.requestSubmit() unless $(e.target.closest(".button")).attr("disabled")

    $(document).off('turbo:before-stream-render').on 'turbo:before-stream-render', (e) =>
      if $(e.target).attr('target') == 'wizard-frame'
        e.preventDefault()
        wizard = $($(e.target.innerHTML)[0].innerHTML)
        step   = wizard.find('[data-ui--wizard-target="step"]').val()
        if wizard.find('.error').length
          $("#wizard-frame").html(wizard)
        else
          $('.wizard-step .content').fadeOut(250)
          setTimeout ->
            wizard.find('.content').hide()
            $("#wizard-frame").html(wizard)
          , 251
          @_stepTo(step)

    for back in @backTargets
      $(back).on 'click', (e) =>
        e.preventDefault()
        step = parseInt($(@stepTarget).val()) - 1
        @_stepTo(step)
        $(".stepper-step").removeClass("active")
        $("#wizard-stepper-#{step}").addClass("active")

  _stepTo: (step) ->
    $('.wizard-step .content').fadeOut(250)
    $('.wizard-step .actions').hide()
    $("#wizard-step-#{step} .actions").show()
    setTimeout ->
      $("#wizard-step-#{step} .content").fadeIn(250)
    , 251
    $(@stepTarget).val(step)
