import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @values  = { url: String }
  @targets = ['control', 'form', 'progress']

  connect: ->
    @checkProgress()

  checkProgress: ->
    $.ajax
      dataType: 'json'
      url: @urlValue
      success: (data) =>
        $(@progressTarget).css(width: "#{data.progress}%")
        if !data.progress_complete && $(document).has(@element).length
          setTimeout(=>
            @checkProgress()
          , 1000)
        else
          $(@progressTarget).css(width: '100%')
          if $(@formTarget).attr('action') == '/progress-tracker:complete'
            setTimeout(=>
              $(@controlTarget).trigger('progress-tracker:complete', data)
            , 500)
          else
            $(@formTarget)[0].requestSubmit()
      error: (data) =>
        $(@element).replaceWith(data.responseJSON.error)
