import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static values = { token: String }

  connect() {
    this.initialize()
  }

  async initialize() {
    const meta     = document.querySelector("meta[name='stripe_key']")
    const stripe   = Stripe(meta.getAttribute("content"))
    const checkout = await stripe.initEmbeddedCheckout({ clientSecret: this.tokenValue })

    checkout.mount("#company-onboarding-trial-checkout")
  }
}
