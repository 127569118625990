import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = ["input"]
  @values  = { textKey: String, valueKey: String, choices: Array }

  connect: ->
    $(@inputTarget).selectize
      valueField:  @valueKeyValue || "value"
      labelField:  @textKeyValue
      searchField: @textKeyValue
      maxItems:    null
      delimiter:   ","
      options:     @choicesValue
      onChange:    =>
        event = document.createEvent("HTMLEvents");
        event.initEvent("change", true, true);
        event.eventName = "change";
        @inputTarget.dispatchEvent(event);

  disconnect: ->
    @inputTarget.selectize.destroy()
