import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @values = { target: String }

  connect: ->
    target = @targetValue
    $(document).on 'turbo:before-stream-render', (e) ->
      # Close the slideout if updating the slide out sream's contents.
      if e.target.innerHTML.indexOf('id="slide-out-stream"') >= 0
        $("##{target}")[0].slideOut.close()

  disconnect: ->
    $(document).off 'turbo:before-stream-render'
