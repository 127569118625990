import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "toggle" ]

  connect() {
    this.toggleTargets.forEach((element) => {
      if (element.dataset.initialShow == "true") {
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    })
  }

  toggle() {
    this.toggleTargets.forEach((element) => {
      element.classList.toggle("hidden")
    })
  }
}
