import ApplicationController from "controllers/application_controller"
import Handsontable from "handsontable"
import { post } from "@rails/request.js"

export default class extends ApplicationController {
  static values  = { departments: Array, roles: Array }
  static targets = ["import", "invite", "sheet"]

  connect() {
    this.sheet = new Handsontable(this.sheetTarget, {
      rowHeaders: false,
      colHeaders: true,
      colHeaders: ["Email*", "First name*", "Last name*", "Role*", "Start date<span class='material-icons-round text-xs ml-1'>today</span>", "Birthdate<span class='material-icons-round text-xs ml-1'>today</span>", "Department", "Title"],
      columns: this.cols(),
      height: "auto",
      licenseKey: "non-commercial-and-evaluation",
      stretchH: "all",
    })
  }

  cols() {
    return [
      { type: "text",         data: "emails" },
      { type: "text",         data: "first_name" },
      { type: "text",         data: "last_name" },
      { type: "autocomplete", data: "type", source: this.rolesValue, strict: true, allowInvalid: false },
      { type: "date",         data: "start_date", dateFormat: "MM/DD/YYYY" },
      { type: "date",         data: "birthdate", dateFormat: "MM/DD/YYYY"  },
      { type: "autocomplete", data: "department_name", source: this.departmentsValue, strict: false, allowInvalid: true },
      { type: "text",         data: "title"  },
    ]
  }

  roles() {
    return {
      "Employee": "Roles::Employee",
      "Manager":  "Roles::Manager",
      "Admin":    "Roles::Admin",
    }
  }

  async save(event) {
    event.preventDefault()
    let schema  = this.sheet.getSettings().columns
    let rows    = this.sheet.getData().filter(v => { return v[0] && v[0].length > 0 })
    let users   = {}
    
    rows.forEach((row, ri) => {
      let user = {}
      row.forEach((cell, i) => {
        user[schema[i]["data"]] = (this.roles()[cell] || cell)
      });
      users[ri] = user
    });

    const response = await post(
      event.params.url,
      {
        body:         JSON.stringify({ user_data: users }),
        contentType:  "application/json",
        responseKind: "json",
      },
    )
    
    if (response.ok) {
      event.target.closest(".modal-control").modal.close()
      this.sheet.clear()
      this.sheet.loadData([{}, {}, {}, {}, {}])
      this.sheet.deselectCell()
    } else {
      const data = await response.text
      event.target.closest(".modal-control").modal.close()
      JSON.parse(data).errors.forEach((row, i) => {
        Object.keys(row).forEach((attr) => { 
          let error = row[attr]
          let col   = this.sheet.propToCol(attr)
          let cell  = this.sheet.getCell(i, col)
          cell.setAttribute("title", error)
          this.sheet.setCellMeta(i, col, "valid", false)
        })
      });  
      this.sheet.render()
    }
  }
}
