import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static values = {
    target: String
  }

  // TODO: this should use a stimulus Target instead of a Value named target
  open(event) {
    document.querySelector(`#${this.targetValue}`).slideOut.open()
  }
}
