import ApplicationController from "controllers/application_controller";
import { useClickOutside } from "stimulus-use"

export default class extends ApplicationController {
  static targets = [
    "conditions",
    "departmentTemplate",
    "hrisTemplate",
    "individualTemplate",
    "titleTemplate",
    "startDateTemplate",
    "startDateOffsetTemplate",
  ];

  connect() {
    useClickOutside(this);
    if (this.conditionsTarget.querySelectorAll(".condition").length > 0) {
      this.conditionsTarget.style.display = "block";
      this.conditionsTarget.querySelectorAll(".condition .fields").forEach((fields) => {
        if (fields.querySelector(".error")) {
          fields.style.display = "block";
        }
      });
    }
  }

  addCondition(e) {
    e.preventDefault();

    this.conditionsTarget.style.display = "block";
    document.querySelectorAll(".condition .fields").forEach(fields => fields.style.display = "none");

    const html = this[`${e.params["template"]}TemplateTarget`];
    const content = document.createElement("div");
    content.innerHTML = html.innerHTML.replace(/NEW_CONDITION/g, new Date().getTime());
    
    const connVal = this.conditionsTarget.querySelector(".connecting-operator-form :checked")?.value;
    const connTxt = this.conditionsTarget.querySelector(".connecting-operator-text")?.textContent || "";

    content.querySelectorAll(".radio").forEach(radioWrapper => {
      const id = Math.random().toString(36).substring(2);
      const radio = radioWrapper.querySelector("#RADIO_ID");
      if (radio.value === connVal) {
        radio.checked = true;
      }
      radio.setAttribute("id", id);
      radioWrapper.querySelector("[for='RADIO_ID']").setAttribute("for", id);
    });

    this.conditionsTarget.appendChild(content);

    if (connTxt.length > 0) {
      content.querySelector(".connecting-operator-text").innerHTML = connTxt;
    }
    content.querySelector(".fields").style.display = "block";
  }

  clickOutside(event) {
    const control = event.target.closest(".connecting-operator-form");
    if (!control && !event.target.classList.contains("connecting-operator-text")) {
      document.querySelectorAll(".connecting-operator-form").forEach(form => form.style.display = "none");
    }
  }

  collapse(event) {
    event.preventDefault()
    event.target.closest(".fields").style.display = "none";
  }

  removeCondition(e) {
    e.preventDefault();

    const condition = e.target.closest(".condition");
    condition.querySelector("._destroy").value = "1";

    if (!condition.querySelector(".id").value) {
      condition.remove();
    } else {
      condition.classList.add("hidden")
    }

    const visibleConditions = Array.from(
      this.conditionsTarget.querySelectorAll(".condition")
    ).filter(condition => condition.offsetParent !== null);

    if (visibleConditions.length === 0) {
      this.conditionsTarget.classList.add("hidden")
    }
  }

  toggleCollapse(event) {
    event.preventDefault()
    const fields = event.target.closest(".condition").querySelector(".fields");
    document.querySelectorAll(".condition .fields").forEach((el) => {
      if (el !== fields) el.style.display = "none";
    });
    fields.style.display = fields.style.display === "block" ? "none" : "block";
  }

  toggleConnectingOperator(event) {
    document.querySelectorAll(".connecting-operator-form").forEach(form => form.style.display = "none");
    const form = event.target.closest(".condition").querySelector(".connecting-operator-form");
    form.style.display = form.style.display === "block" ? "none" : "block";
  }

  updateConnectingOperator(event) {
    const text = event.target.getAttribute("label");
    document.querySelectorAll(`.connecting-operator-input[value='${event.target.value}']`).forEach(input => {
      input.checked = true;
    });
    document.querySelectorAll(".connecting-operator-text").forEach(textElement => {
      textElement.innerHTML = text;
    });
    event.target.closest(".condition").querySelector(".connecting-operator-form").style.display = "none";
  }

  updateLogicalOperatorText(event) {
    if (event.target.matches(".condition .logical-operator-input")) {
      const text = event.target.selectedOptions[0].text;
      event.target.closest(".condition").querySelector(".logical-operator-text").innerHTML = text;
    }
  }

  updateVariableValueText(event) {
    let text = "";
    if (event.target.tagName === "SELECT") {
      text = event.target.selectedOptions[0].text;
    } else if (event.target.closest(".pillbox")) {
      text = Array.from(document.querySelectorAll(".selectize-input .item")).map(item => item.textContent).join(", ");
    } else {
      text = event.target.value;
    }
    event.target.closest(".condition").querySelector(".variable-value-text").innerHTML = text;
  }
}
