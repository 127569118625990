import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = ["amount"]

  connect: ->
    $(@amountTarget).on "change", (e) =>
      @calculate($(e.target))
    $(@amountTarget).on "focus", ->
      $(@).select()

  calculate: (input) ->
    id = input.attr("id").replace("match_", "match_calculation_")
    $("##{id}").val(input.val())
    $("#match-calculation-form")[0].requestSubmit()

  disconnect: ->
    $(@amountTarget).off("change")
