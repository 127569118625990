import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "page", "per", "reset", "restore" ]

  // Set the page param to true to the given page number to retore the table at
  // that page if the cached page is loaded again.
  page(page) {
    this.pageTarget.value = page
  }

  submitForm() {
    this.element.requestSubmit();
  }
}
