import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = ["field", "preview", "trigger"]

  connect: ->
    $(@triggerTarget).on "click", (e) =>
      e.preventDefault()
      $(@fieldTarget).click()

    $(@fieldTarget).on "change", (e) =>
      file = e.target.files && e.target.files[0]
      if file
        reader = new FileReader()
        reader.onload = (e) =>
          $(@previewTarget).attr("src", e.target.result)
        reader.readAsDataURL(file)

  disconnect: ->
    $(@triggerTarget).off("click")
    $(@fieldTarget).off("change")
