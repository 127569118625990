import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["per"]

  connect() {
    let frame = document.getElementById("department-employees-sheet-frame")
    let per = parseInt((window.innerHeight - frame.offsetTop - 100) / 27)
    let url   = this.element.getAttribute("action")

    this.perTarget.value = per
    if (!document.getElementById("department-employees-sheet")) {
      frame.setAttribute("src", `${url}?per=${per}`)
    }
  }

  search(){
    this.element.requestSubmit();
  }
}
