import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["input"]

  connect() {
    this.toggleClass(this.inputTarget)
  }

  select(event) {
    this.toggleClass(event.target)
  }

  toggleClass(element){
    if (element.value.length > 0) {
      element.classList.add("has-value")
    } else {
      element.classList.remove("has-value")
    }
  }
}
