import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "links", "template", "form" ]

  addMilestone(event){
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_MILESTONE/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeMilestone(event){
    event.preventDefault()

    let wrapper = event.target.closest(".nested-fields")
    wrapper.remove()
  }
}
