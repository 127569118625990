import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController

  @targets = ['select']
  @values  = { textKey: String, valueKey: String, url: String }

  connect: ->
    $(@selectTarget).selectize
      valueField:  @valueKeyValue || 'value'
      labelField:  @textKeyValue || "name"
      searchField: @textKeyValue || "name"
      onChange: ->
        event = document.createEvent("HTMLEvents")
        event.initEvent("searchselect:change", true, true)
        event.eventName = "searchselect:change"
        @.$input[0].dispatchEvent(event);
      onFocus: ->
        $(@.$wrapper).find('input[type="select-one"]').attr('autocomplete', 'off')
      load: (search, callback) =>
        return callback() unless (@urlValue || '').length
        return callback() unless search.length
        $.ajax
          url: @urlValue
          dataType: 'json'
          data: { search: search }
          success: (data) ->
            callback(data)

  disconnect: ->
    @selectTarget.selectize.destroy()
